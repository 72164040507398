// REACT
import React from "react"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { RentalsBlurb } from "../components/rentalsBlurb"

// COMPONENT FUNCTION
const RentalsSunfishPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      rentalsSunfish01: file(relativePath: { eq: "rentals-sunfish-01.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Rentals - Sunfish">
      <PageTitle title="SUNFISH RENTAL" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.rentalsSunfish01.childImageSharp.fluid}
          caption="A Sunfish boat underway"
          displayCaption
          maxWidth={480}
        />
      </ImageBlock>

      <RentalsBlurb />
    </Layout>
  )
}

export default RentalsSunfishPage
